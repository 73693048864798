@import "~antd/dist/antd.less";

.ant-layout-header {
  height: 66px;
}

.desktop-header {
  .ant-menu-item,
  .ant-menu-submenu-title {
    font-size: 16px;
  }
}

.bhv-card-wrapper {
  .ant-card-head {
    height: 42px;
  }
  .ant-card-head-title {
    padding-bottom: 6px;
    padding-top: 8px;

    .ant-row {
      margin-bottom: 0 !important;
    }
  }
}
[data-theme="compact"]
  .site-collapse-custom-collapse
  .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: #fff;
  border: 0px;
  border-radius: 2px;
}

.ant-collapse-content {
  .ant-collapse-content-box {
    padding-left: 0;
    padding-right: 0;
  }
}

.product-menu {
}

@primary-color: #f76420;